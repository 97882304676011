<template>
  <sdCards headless class="option-card">
    <h2>{{ i18n.t('option.attr.code') }} «{{ data.item.code }}»</h2>
    <div class="option-card__description">
      <ul class="option-card__properties mt-10">
        <li>
          <p>{{ i18n.t('option.attr.name') }}</p>
          <p>{{ data.item.name }}</p>
        </li>
        <li>
          <p>{{ i18n.t('option.attr.multiplier') }}</p>
          <p>x{{ data.item.multiplier }}</p>
        </li>
        <li>
          <p>{{ i18n.t('option.attr.amount') }}</p>
          <p>{{ data.amount }} {{ data.amount_currency }}</p>
        </li>
      </ul>
    </div>
  </sdCards>
</template>

<script>
import { useI18n } from 'vue-i18n';
const CardOption = {
  name: 'CardOption',
  props: {
    data: {
      type: Object,
      default() {
        return {
          item: {
            name: null,
            code: null,
            multiplier: null,
          },
          amount: null,
          amount_currency: null,
        };
      },
    },
  },
  setup() {
    const i18n = useI18n();

    return { i18n };
  },
};
export default CardOption;
</script>

<style scoped lang="scss">
.option-card {
  h2 {
    margin-bottom: 1em;
  }
  .option-card__properties {
    font-size: 14px;
    column-count: 3;
    column-gap: 5px;

    //li {
    //  max-width: 33%;
    //}
    p {
      margin: 0 0 5px 0;
    }
    p:nth-last-child(1) {
      font-size: 16px;
      font-weight: 800;
      margin: 0;
    }
  }
}
</style>
